const HandsonsData_WinbondHoliday = {};

HandsonsData_WinbondHoliday.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
};

HandsonsData_WinbondHoliday.colHeaders_Name = ['選択', 'WEC休日', 'WECJ休日'];

HandsonsData_WinbondHoliday.columns_Data = [
  {
    data: 'delete_Flag',
    type: 'checkbox',
    className: 'htCenter',
    checkedTemplate: '1',
    uncheckedTemplate: '0',
  },
  {
    data: 'WEC_holiday',
    type: 'date',
    dateFormat: 'YYYY/MM/DD',
    className: '',
  },
  {
    data: 'WECJ_holiday',
    type: 'date',
    dateFormat: 'YYYY/MM/DD',
    className: '',
  },
];

HandsonsData_WinbondHoliday.data = [];

HandsonsData_WinbondHoliday.sampledata = [
  {
    delete_Flag: '0',
    partition_key: 'WinbondHoliday',
    create_time: '',
    WEC_holiday: '',
    WECJ_holiday: '',
  },
];

HandsonsData_WinbondHoliday.testdata = [
  {
    delete_Flag: '0',
    partition_key: 'WinbondHoliday',
    create_time: '',
    WEC_holiday: '',
    WECJ_holiday: '',
  },
];

export default HandsonsData_WinbondHoliday;
