const HandsonsData_Customer = {}

HandsonsData_Customer.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
}



HandsonsData_Customer.colHeaders_Name = [
  '削除',
  'End Customer Alias',
  'End Customer ID',
  'Disti',
  'Remark',
  // 'create_time',
  // '代理店',
  // '代理店（日本語）',
  // '代理店ID',
  // '代理店郵便番号',
  // '代理店住所',
  // '代理店電話番号',
  // '代理店担当者1（窓口）',
  // '代理店担当者2（窓口）',
  // 'JA担当者',
  // 'JQ担当者',
  // '作成日',
  // 'yobi' 
];



HandsonsData_Customer.columns_Data = [
  {
    data: 'delete_Flag',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  { data: 'EndCustomer' },
  { data: 'EndCustomerID' },
  { data: 'Disti' },
  { data: 'Remark' },
  // {data: 'create_time', readOnly: true },
  // {
  //   data: 'EndCustomer',
  //   type: "dropdown",
  //   source:["aaa","bbb","ccc","ddd" ],
  // },

  // { data: 'EndCustomerJP' },
  // { data: 'EndCustomerID' },
  // { data: 'CustomerGroupJQ' },
  // { data: 'application' },
  // { data: 'affiliate' },
  // { data: 'affiliateJP' },
  // { data: 'affiliateID' },
  // { data: 'affiliateZip' },
  // { data: 'affiliateAddress' },
  // { data: 'affiliateTel' },
  // { data: 'affiliateStaff1' },
  // { data: 'affiliateStaff2' },
  // { data: 'JAStaff' },
  // { data: 'JQStaff' },
  // {data: 'partition_key', readOnly: true},

  // {data: 'mode'},
  // {data: 'type'},
  // {data: 'status'},
  // {data: 'Request_No'},
  // {data: 'Project_No'},
  // {data: 'yobi'}
];


HandsonsData_Customer.data = [
];

HandsonsData_Customer.sampledata = [
  {
    delete_Flag: "0",
    partition_key: "Customer",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    EndCustomer: "",
    EndCustomerID: "",
    Disti: "",
    Remark: "",
    // affiliate: "",
    // affiliateJP: "",
    // affiliateID: "",
    // affiliateZip: "",
    // affiliateAddress: "",
    // affiliateTel: "",
    // affiliateStaff1: "",
    // affiliateStaff2: "",
    // JAStaff: "",
    // JQStaff: "",
    // yobi: ""
  },
];


HandsonsData_Customer.testdata = [
  {
    delete_Flag: "",
    EndCustomer: "ATSUMI",
    EndCustomerID: "151408572",
    EndCustomerJP: "アイルミッション",
    Disti: "",
    Remark: "",
    CustomerGroupJQ: "ATSUMI",
    application: "HEA",
    affiliate: "RYOSAN",
    affiliateJP: "株式会社リョーサン",
    affiliateID: "TD5011",
    affiliateZip: "101-0031",
    affiliateAddress: "東京都千代田区東神田2-3-5",
    affiliateTel: "03-5294-1290",
    affiliateStaff1: "剥岩　一高",
    affiliateStaff2: "",
    JAStaff: "SHIMIZU",
    JQStaff: "YAMAMOTO"
  },
  {
    delete_Flag: "",
    EndCustomer: "AUDIO-TECHNICA",
    EndCustomerJP: "株式会社オーディオテクニカ",
    EndCustomerID: "151412067",
    Disti: "",
    Remark: "",
    CustomerGroupJQ: "AUDIO-TECHNICA",
    application: "HEA",
    affiliate: "KFT",
    affiliateJP: "兼松フューチャーテックソリューションズ株式会社",
    affiliateID: "110001149",
    affiliateZip: "104-0032",
    affiliateAddress: "東京都中央区八丁堀1-10-7　TMG八丁堀ビル8F",
    affiliateTel: "03-5542-5936",
    affiliateStaff1: "",
    affiliateStaff2: "",
    JAStaff: "AKABA",
    JQStaff: "OMIKAWA"
  },
  {
    delete_Flag: "",
    EndCustomer: "CALSONIC KANSEI",
    EndCustomerJP: "カルソニックカンセイ株式会社",
    EndCustomerID: "151412474",
    Disti: "",
    Remark: "",
    CustomerGroupJQ: "CALSONIC KANSEI",
    application: "CAR",
    affiliate: "FEI",
    affiliateJP: "富士通エレクトロニクス株式会社",
    affiliateID: "T92069",
    affiliateZip: "222-8508",
    affiliateAddress: "神奈川県港北区新横浜2-100-45　新横浜中央ビル",
    affiliateTel: "045-415-5831",
    affiliateStaff1: "",
    affiliateStaff2: "",
    JAStaff: "HAMADA",
    JQStaff: "HACHISUKA"
  },
  {
    delete_Flag: "",
    EndCustomer: "CANON",
    EndCustomerJP: "キャノン株式会社",
    EndCustomerID: "151400263",
    Disti: "",
    Remark: "",
    CustomerGroupJQ: "CANON",
    application: "HANDHELD",
    affiliate: "FEI",
    affiliateJP: "富士通エレクトロニクス株式会社",
    affiliateID: "T92069",
    affiliateZip: "222-8508",
    affiliateAddress: "神奈川県港北区新横浜2-100-45　新横浜中央ビル",
    affiliateTel: "045-415-5831",
    affiliateStaff1: "",
    affiliateStaff2: "",
    JAStaff: "TAMURA",
    JQStaff: "OMIKAWA"
  },
  {
    delete_Flag: "",
    EndCustomer: "CLARION (MY)",
    EndCustomerJP: "クラリオン株式会社",
    EndCustomerID: "151412919",
    Disti: "",
    Remark: "",
    CustomerGroupJQ: "CLARION",
    application: "CAR",
    affiliate: "RYOSAN",
    affiliateJP: "株式会社リョーサン",
    affiliateID: "TD5011",
    affiliateZip: "101-0031",
    affiliateAddress: "東京都千代田区東神田2-3-5",
    affiliateTel: "03-5294-1290",
    affiliateStaff1: "剥岩　一高",
    affiliateStaff2: "",
    JAStaff: "YOSHITAKE",
    JQStaff: "FUJITA"
  },
  {
    delete_Flag: "",
    EndCustomer: "CONTEC",
    EndCustomerJP: "株式会社コンテック",
    EndCustomerID: "151409177",
    Disti: "",
    Remark: "",
    CustomerGroupJQ: "CONTEC",
    application: "INDUSTRY",
    affiliate: "MACNICA",
    affiliateJP: "株式会社マクニカ",
    affiliateID: "110001211",
    affiliateZip: "222-8561",
    affiliateAddress: "神奈川県横浜市新横浜1-6-3　マクニカ第1ビル",
    affiliateTel: "045-470-9841",
    affiliateStaff1: "",
    affiliateStaff2: "",
    JAStaff: "TAMURA",
    JQStaff: "OMIKAWA"
  },
  {
    delete_Flag: "",
    EndCustomer: "DAIICHI SHOKAI",
    EndCustomerJP: "株式会社大一商会",
    EndCustomerID: "151408980",
    Disti: "",
    Remark: "",
    CustomerGroupJQ: "DAIICHI SHOKAI",
    application: "INDUSTRY",
    affiliate: "KFT",
    affiliateJP: "兼松フューチャーテックソリューションズ株式会社",
    affiliateID: "110001149",
    affiliateZip: "104-0032",
    affiliateAddress: "東京都中央区八丁堀1-10-7　TMG八丁堀ビル8F",
    affiliateTel: "03-5542-5936",
    affiliateStaff1: "",
    affiliateStaff2: "",
    JAStaff: "NOJIRI",
    JQStaff: "OMIKAWA"
  }


];


export default HandsonsData_Customer
