const HandsonsData_ApplicationCode = {};

HandsonsData_ApplicationCode.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
};

HandsonsData_ApplicationCode.colHeaders_Name = [
  '選択',
  'Application Code',
  'Application Category',
  'Application Category 1',
  'Application Category 2',
];

HandsonsData_ApplicationCode.columns_Data = [
  {
    data: 'delete_Flag',
    type: 'checkbox',
    className: 'htCenter',
    checkedTemplate: '1',
    uncheckedTemplate: '0',
  },
  { data: 'create_time', className: '' },
  { data: 'Application_Category', className: '' },
  { data: 'Application_Category_1', className: '' },
  { data: 'Application_Category_2', className: '' },
];

HandsonsData_ApplicationCode.data = [];

HandsonsData_ApplicationCode.sampledata = [
  {
    delete_Flag: '0',
    partition_key: 'ApplicationCode',
    create_time: '',
    Application_Category: '',
    Application_Category_1: '',
    Application_Category_2: '',
  },
];

export default HandsonsData_ApplicationCode;
