const HandsonsData_WECJSales = {};

HandsonsData_WECJSales.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
};

HandsonsData_WECJSales.colHeaders_Name = ['選択', 'WECJ SALES', 'detail'];

HandsonsData_WECJSales.columns_Data = [
  {
    data: 'delete_Flag',
    type: 'checkbox',
    className: 'htCenter',
    checkedTemplate: '1',
    uncheckedTemplate: '0',
  },
  { data: 'WECJ_SALES', className: '' },
  { data: 'detail', className: '' },
];

HandsonsData_WECJSales.data = [];

HandsonsData_WECJSales.sampledata = [
  {
    delete_Flag: '0',
    partition_key: 'WECJSales',
    create_time: '',
    WECJ_SALES: '',
    detail: '',
  },
];

HandsonsData_WECJSales.testdata = [
  {
    delete_Flag: '0',
    partition_key: 'WECJSales',
    create_time: '',
    WECJ_SALES: '',
    detail: '',
  },
];

export default HandsonsData_WECJSales;
