const HandsonsData_Users = {}

HandsonsData_Users.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  mail_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
}


HandsonsData_Users.colHeaders_Name = [
  '選択',
  'Group No<br>（Disti ID）',
  'Group Name',
  'User Name',
  'Login ID',
  'Password',
  'Mail',
  'Page<br>Document<br>Request',
  'Page<br>CFAR<br>Request',
  'Page<br>Document',
  'Page<br>CFAR',
  'Page<br>Customer',
  'Page<br>Users',
  'Page<br>Logs',
  // '作成日' 
  // '権限<br>Tools',
  // '権限<br>依頼管理<br>Document<br>（全件表示）',
  // '権限<br>依頼管理<br>CFAR<br>（全件表示）',
  // 'yobi' 
];




HandsonsData_Users.columns_Data = [
  {
    data: 'delete_Flag',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  { data: 'User_GroupID',     className:'htRight', },
  { data: 'User_GroupName' },
  { data: 'User_Name' },
  { data: 'User_ID' },
  { data: 'User_Pass' },
  { data: 'User_Mail' },
  {
    data: 'auth_OrderDocument',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'auth_OrderCFAR',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'auth_Document',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'auth_CFAR',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'auth_Costomer',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'auth_Users',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'auth_Logs',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  // {data: 'create_time', readOnly: true },

  // {
  //   data: 'auth_Tools',
  //   type: "checkbox",
  //   className:'htCenter',
  //   checkedTemplate: "1",
  //   uncheckedTemplate: "0"
  // },
  // {
  //   data: 'auth_OrderDocumentAll',
  //   type: "checkbox",
  //   className:'htCenter',
  //   checkedTemplate: "1",
  //   uncheckedTemplate: "0"
  // },
  // {
  //   data: 'auth_OrderCFARAll',
  //   type: "checkbox",
  //   className:'htCenter',
  //   checkedTemplate: "1",
  //   uncheckedTemplate: "0"
  // },
  // {
  //   data: 'yobi',
  //   type: "checkbox",
  //   checkedTemplate: "1",
  //   uncheckedTemplate: "0"
  // },
];


HandsonsData_Users.data = [
];

HandsonsData_Users.sampledata = [
  {
    delete_Flag: "0",
    User_GroupID: "",
    User_GroupName: "",
    User_Name: "",
    User_ID: "",
    User_Pass: "",
    User_Mail: "",
    auth_Document: "0",
    auth_CFAR: "0",
    auth_OrderDocument: "0",
    auth_OrderCFAR: "0",
    auth_Costomer: "0",
    auth_Users: "0",
    auth_Logs: "0",
    partition_key: "User",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    // auth_Tools: "0",
    // auth_OrderDocumentAll: "0",
    // auth_OrderCFARAll: "0",
  },
];


HandsonsData_Users.testdata = [
  {
    delete_Flag: "",
    User_Name: "伊藤洋介",
    User_ID: "user",
    User_Pass: "pass",
    User_Mail: "yosuke_ito@aillumission.co.jp",
    User_GroupName: "Aillumission",
    User_GroupID: "009",
    auth_Costomer: "1",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '001',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "川越義直",
    User_Mail: "ykawagoe@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "0",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '002',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "吉井一郎",
    User_Mail: "iyoshii@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "1",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '003',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "森本直美",
    User_Mail: "nmorimoto@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "1",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '004',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "岡本良子",
    User_Mail: "rokamoto0@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "1",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '005',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "千葉京子",
    User_Mail: "chibak@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "0",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '006',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "松原清",
    User_Mail: "kmatsubara@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "1",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '007',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "山本幸夫",
    User_Mail: "yyamamoto@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "0",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '008',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "Master管理者",
    User_Mail: "administrator@test.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "1",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '009',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "藤田佳幸",
    User_Mail: "yfujita@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "1",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "1",
    partition_key: "User",
    create_time: '010',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "木村俊郎",
    User_Mail: "tkimura@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "0",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "1",
    partition_key: "User",
    create_time: '011',
    mode: "mode",
    type: "type",
    status: "status",
  },
  {
    delete_Flag: "",
    User_Name: "大見川斉文",
    User_Mail: "omikawa@winbond.com",
    User_ID: "user",
    User_Pass: "pass",
    User_GroupName: "Winbond Electronics",
    User_GroupID: "009",
    auth_Costomer: "1",
    auth_Document: "1",
    auth_CFAR: "1",
    auth_Tools: "1",
    auth_Logs: "1",
    auth_Users: "1",
    auth_OrderDocument: "1",
    auth_OrderDocumentAll: "1",
    auth_OrderCFAR: "1",
    auth_OrderCFARAll: "0",
    partition_key: "User",
    create_time: '012',
    mode: "mode",
    type: "type",
    status: "status",
  }

];


export default HandsonsData_Users
