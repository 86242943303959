const HandsonsData_PartNoVsProductNo = {};

HandsonsData_PartNoVsProductNo.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
};

HandsonsData_PartNoVsProductNo.colHeaders_Name = [
  '選択',
  'Product Type',
  'Density',
  'Part Name',
  'Part Number',
  'Package',
  'Grade',
  'Product name',
  'Die Version',
  'Die Grade',
  'Funcition',
  'R-ID<br>Chip rel. report',
  'P-ID<br>T&R',
  'P-ID<br>Tray',
  'P-ID<br>Tube',
  'Q-ID<br>QC flow',
  'F-ID<br>Reflow',
  'F-ID<br>手はんだ',
  'Mark-ID<br>(A1,C1,H1)',
  'X-ID<br>X section',
  'T-ID<br>Tray Draw',
  'Y-ID<br>REACH',
  'Z-ID<br>ICP',
  'M-ID<br>MSDS',
  'S-ID<br>Solederbility',
  'W-ID<br>Whiske',
];

HandsonsData_PartNoVsProductNo.columns_Data = [
  {
    data: 'delete_Flag',
    type: 'checkbox',
    className: 'htCenter',
    checkedTemplate: '1',
    uncheckedTemplate: '0',
  },
  { data: 'Product_Type' },
  { data: 'Density' },
  { data: 'Part_Name' },
  { data: 'create_time' }, // Part_Numberをキーとするためにcreate_timeに置き換え
  { data: 'Package' },
  { data: 'Grade' },
  { data: 'Product_name' },
  { data: 'Die_Version' },
  { data: 'Die_Grade' },
  { data: 'Funcition' },
  { data: 'R_ID_for_Chip_rel_report' },
  { data: 'P_ID_Packing_Spec_for_T_R' },
  { data: 'P_ID_Packing_Spec_for_Tray' },
  { data: 'P_ID_Packing_Spec_for_Tube' },
  { data: 'Q_ID_for_QC_flow' },
  { data: 'F_ID_Reflow' },
  { data: 'F_ID_Reflow_手はんだ' },
  { data: 'Mark_ID_A1_C1_H1' },
  { data: 'X_ID_for_X_section' },
  { data: 'T_ID_for_Tray_Draw' },
  { data: 'Y_ID_REACH' },
  { data: 'Z_ID_for_ICP' },
  { data: 'M_ID_for_MSDS' },
  { data: 'S_ID_for_Solederbility' },
  { data: 'W_ID_for_Whiske' },
];

HandsonsData_PartNoVsProductNo.data = [];

HandsonsData_PartNoVsProductNo.sampledata = [
  {
    delete_Flag: '0',
    partition_key: 'PartNo_vs',
    create_time: '',
    Product_Type: '',
    Density: '',
    Part_Name: '',
    Package: '',
    Grade: '',
    Product_name: '',
    Die_Version: '',
    Die_Grade: '',
    Funcition: '',
    R_ID_for_Chip_rel_report: '',
    P_ID_Packing_Spec_for_T_R: '',
    P_ID_Packing_Spec_for_Tray: '',
    P_ID_Packing_Spec_for_Tube: '',
    Q_ID_for_QC_flow: '',
    F_ID_Reflow: '',
    F_ID_Reflow_手はんだ: '',
    Mark_ID_A1_C1_H1: '',
    X_ID_for_X_section: '',
    T_ID_for_Tray_Draw: '',
    Y_ID_REACH: '',
    Z_ID_for_ICP: '',
    M_ID_for_MSDS: '',
    S_ID_for_Solederbility: '',
    W_ID_for_Whiske: '',
  },
];

HandsonsData_PartNoVsProductNo.testdata = [
  {
    delete_Flag: '0',
    partition_key: 'PartNo_vs',
    create_time: 'W25H01JVSFAM',
    Product_Type: 'Flash',
    Density: '1Gbit',
    Part_Name: 'Serial NOR Flash Memory',
    Package: 'SOP16(300)',
    Grade: 'Automotive',
    Product_name: 'AAG070',
    Die_Version: '58nm',
    Die_Grade: 'AG2',
    Funcition: '',
    R_ID_for_Chip_rel_report: 'R15',
    P_ID_Packing_Spec_for_T_R: 'P2',
    P_ID_Packing_Spec_for_Tray: 'P3',
    P_ID_Packing_Spec_for_Tube: 'P4',
    Q_ID_for_QC_flow: 'Q2',
    F_ID_Reflow: 'F1',
    F_ID_Reflow_手はんだ: 'F2',
    Mark_ID_A1_C1_H1: 'C15',
    X_ID_for_X_section: 'X11',
    T_ID_for_Tray_Draw: 'T6',
    Y_ID_REACH: 'Y1',
    Z_ID_for_ICP: 'Z1',
    M_ID_for_MSDS: 'M1',
    S_ID_for_Solederbility: 'S1',
    W_ID_for_Whiske: 'W1',
  },
];

export default HandsonsData_PartNoVsProductNo;
