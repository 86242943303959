const HandsonData_M_ID = {
  defaultState: {
    activeTab: '1',

    stateModal: false,
    messege: 'sample messege',
    messege2: '',
    messege3: '',
    messege4: '',
    messege5: '',

    write_disabled: true,
    delete_disabled: true,
    Button_hidden: false,
    New_hidden: true,
    Details_hidden: true,
    Admin_hidden: true,

    Btn_hidden_Regist: true,
    Btn_hidden_Fix: true,
    Btn_hidden_Cansel: true,
    Btn_hidden_EditSave: true,
    Btn_hidden_Remand: true,
    Btn_hidden_Approval: true,
  },
  colHeaders_Name: ['選択', 'M-ID', 'Q-system file name'],
  columns_Data: [
    {
      data: 'delete_Flag',
      type: 'checkbox',
      className: 'htCenter',
      checkedTemplate: '1',
      uncheckedTemplate: '0',
    },
    { data: 'create_time', className: '' },
    { data: 'Q_system_file_name', className: '' },
  ],
  data: [],
  sampledata: [
    {
      delete_Flag: '0',
      partition_key: 'M_ID',
      create_time: '',
      Q_system_file_name: '',
    },
  ],
  testdata: [
    {
      delete_Flag: '0',
      partition_key: 'M_ID',
      create_time: 'M1',
      Q_system_file_name: 'SOP16(300)_MSDSReport.zip',
    },
  ],
};

export default HandsonData_M_ID;
