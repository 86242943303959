const HandsonsData_AgencyInformation = {};

HandsonsData_AgencyInformation.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
};

HandsonsData_AgencyInformation.colHeaders_Name = [
  '選択',
  'End Customer Alias',
  'Orig_SoldTo',
  'DISTRIBUTOR',
  '表紙用代理店正式社名',
  '表紙用代理店住所',
  '表紙用代理店TEL',
  'グループメールアドレス',
];

HandsonsData_AgencyInformation.columns_Data = [
  {
    data: 'delete_Flag',
    type: 'checkbox',
    className: 'htCenter',
    checkedTemplate: '1',
    uncheckedTemplate: '0',
  },
  { data: 'End_Customer_Alias', className: '' },
  { data: 'Orig_SoldTo', className: '' },
  { data: 'DISTRIBUTOR', className: '' },
  { data: 'Official_company_name_cover', className: '' },
  { data: 'Cover_agency_address', className: '' },
  { data: 'Cover_agency_TEL', className: '' },
  { data: 'Group_Mail_address', className: '' },
];

HandsonsData_AgencyInformation.data = [];

HandsonsData_AgencyInformation.sampledata = [
  {
    delete_Flag: '0',
    partition_key: 'AgencyInformation',
    create_time: '',
    End_Customer_Alias: '',
    Orig_SoldTo: '',
    DISTRIBUTOR: '',
    Official_company_name_cover: '',
    Cover_agency_address: '',
    Cover_agency_TEL: '',
    Group_Mail_address: '',
  },
];

export default HandsonsData_AgencyInformation;
