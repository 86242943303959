const HandsonsData_SubconCode = {};

HandsonsData_SubconCode.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
};

HandsonsData_SubconCode.colHeaders_Name = ['選択', 'Vendor Code', '原産国', 'Vendor/Subcon'];

HandsonsData_SubconCode.columns_Data = [
  {
    data: 'delete_Flag',
    type: 'checkbox',
    className: 'htCenter',
    checkedTemplate: '1',
    uncheckedTemplate: '0',
  },
  { data: 'Vendor_Code', className: '' },
  { data: 'Country_Origin', className: '' },
  { data: 'Vendor_Subcon', className: '' },
];

HandsonsData_SubconCode.data = [];

HandsonsData_SubconCode.sampledata = [
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: '',
    Country_Origin: '',
    Vendor_Subcon: '',
  },
];

HandsonsData_SubconCode.testdata = [
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'AC',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Advanced Semiconductor Engineering Inc.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'CM',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'ChipMOS TECHNOLOGIES INC.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'AL',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'AMKOR (Taiwan, Sampo)',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'AS',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Advanced Semiconductor Engineering Inc.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'FH',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'FU WIN TECH CO., LTD.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'FM',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Formosa Advanced Technologies Co., LTD.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'GR',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Greatek Electronics Inc.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'LI',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Lingsen Precision Inductries, LTD.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'OS',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Orient Semiconductor Electronics, Ltd.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'PE',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Pointec Technology Co., Ltd. (Hsin-Chu)',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'SZ',
    Country_Origin: 'China',
    Vendor_Subcon: 'Siliconware Technology (SuZhou) Limited',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'PT',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Probe Test System Corp.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'SG',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Sigurd Microelectronics Corporation',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'SP',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Siliconware Precision Industries Co., Ltd',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'TF',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Taiwan IC Packaging Corporation',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'WA',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Walton Advanced Engineering, Inc.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'WS',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'King Yuan Electronics Co., LTD.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'ZK',
    Country_Origin: 'China',
    Vendor_Subcon: 'SuZhou Zhen Kun Tenology LTD.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'NF',
    Country_Origin: 'China',
    Vendor_Subcon: 'Nantong Fujitsu Microelectronics Co., Ltd',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'CN',
    Country_Origin: 'China',
    Vendor_Subcon: 'ChipMOS TECHNOLOGIES INC.(China)',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'ST',
    Country_Origin: 'China',
    Vendor_Subcon: 'SiTEC Semiconductor Ltd.(?)',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'WE',
    Country_Origin: 'China',
    Vendor_Subcon: 'Walton Advanced Engineering (Suzhou), Inc.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'GA',
    Country_Origin: 'China',
    Vendor_Subcon: 'ASE assembly & testing (Shanghai) Limited',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'TW',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'TeraPower Technology Inc.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'TC',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Tieh Chih Precision Industry Corp.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'QM',
    Country_Origin: 'China',
    Vendor_Subcon: 'China WLCSP Co., LTD',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'PS',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Powertech Technology Inc.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'UT',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Ultra Tera Corporation',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'KY',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'KUN YUAN TECHNOLOGY Co., LTD.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'QP',
    Country_Origin: 'Portugal',
    Vendor_Subcon: 'Qimonda (Porto)',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'PZ',
    Country_Origin: 'China',
    Vendor_Subcon: 'Powertech Technology Inc. Suzhou Plant',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'GS',
    Country_Origin: 'China',
    Vendor_Subcon: 'Advanced Semiconductor Engineering. Inc. (KunShan)',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'MT',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Macrotech Technology Inc.',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'TH',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Silicon Test Tech Corp. (SiTEC)',
  },
  {
    delete_Flag: '0',
    partition_key: 'SubconCode',
    create_time: '',
    Vendor_Code: 'WI',
    Country_Origin: 'Taiwan',
    Vendor_Subcon: 'Winstek Semiconductor Co., Ltd.',
  },
];

export default HandsonsData_SubconCode;
